<template>
    <div v-if="aws_env">
        <h1>Dev Stuff</h1>

        <br />superbills_statements:<br />
        <button v-if="aws_env" @click.prevent="superbills_statements">superbills_statements</button>
        <div style="border:1px solid green;">
            <br />
            &nbsp;
            {{ check_superbills_statements }}
            <br />
            &nbsp;
        </div>
        <br />
        <hr />
        <br />
        <br />check_send_emails:<br />
        <button type="button" v-if="aws_env" @click.prevent="check_send_emails_cron">check_send_emails</button>
        <br />
        <div style="border:1px solid green;">
            <br />
            &nbsp;
            {{ check_send_emails }}
            <br />
            &nbsp;
        </div>
        <br />
        <hr />
        <br />

        <br />check_progress_notes_cron:<br />
        <button type="button" v-if="aws_env" @click.prevent="check_progress_notes_cron">check_progress_notes</button>
        <br />
        <div style="border:1px solid green;">
            <br />
            &nbsp;
            {{ check_progress_notes }}
            <br />
            &nbsp;
        </div>
        <br />
        <hr />
        <br />check_statements_superbills_cron: (note that this may take several minutes to complete)<br />
        <button type="button" v-if="aws_env" @click.prevent="check_statements_superbills">check_statements_superbills</button>
        <br />
        <div style="border:1px solid green;">
            <br />
            &nbsp;
            {{ echo_statements_superbills }}
            <br />
            &nbsp;
        </div>
        <br />
        <hr />
        <br />
        <br />insurance api test:<br />
        <button type="button" v-if="aws_env" @click.prevent="echo_test_insurance_cron">echo_test_insurance</button>
        <br />
        <div style="border:1px solid green;">
            insurance API echo test : <br />
            {{ echo_test_insurance }}
        </div>

        <br />
        <hr />
        <br />
        <br />insurance api test:<br />
        <button type="button" v-if="aws_env" @click.prevent="temp_update_eras_cron">temp_update_eras</button>
        <br />
        <div style="border:1px solid green;">
            ERAS (Remits) : <br />
            {{ temp_update_eras }}
        </div>


        <br />
        <hr />
        <br />
        <br />trigger_new_client workflow test:<br />
        client id : <input v-model="client_id">
        <button type="button" v-if="aws_env" @click.prevent="trigger_new_client_func">trigger_new_client</button>
        <br />
        <div style="border:1px solid green;">
            new client : <br />
            {{ trigger_new_client }}
        </div>
    </div>
</template>

<script>
    /*
     <ul>
            <li v-for="(page, i) in availablePages" :key="i">
                <router-link :to="{ name: 'DynamicForm', params: { pageName: page.component_name } }">{{
                    page.page
                }}</router-link>
            </li>
        </ul>
        <img src="@/assets/px/client-profile-placeholder.png" alt="BirchNotes" title="BirchNotes" />
     */
    import { mapGetters } from 'vuex';
    export default {
        name: 'Dev',
        data() {
            return {
                loading: true,
                client_id: 0,
                temp_update_eras: {},
                echo_test_insurance: {},
                check_superbills_statements: {},
                check_progress_notes: {},
                check_send_emails: {},
                trigger_new_client: [],
                echo_statements_superbills: {},
                aws_env: 0
            };
        },
        methods: {
            async load_data() {
                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async temp_update_eras_cron() {
                const res = await this.$api.post(`/insurance/update_eras`, {});
                this.temp_update_eras_cron = res.data;
            },
            async trigger_new_client_func() {
                const payload =  { user_id: this.$store.state.user.id, client_id: this.client_id };
                const res = await this.$api.put(`/form_data/create-all-portal2signs`, payload);
                this.trigger_new_client = res.data;
            },
            async echo_test_insurance_cron() {
                const res = await this.$api.post(`/insurance/echo_test`, {});
                this.echo_test_insurance = res.data;
            },
            async superbills_statements() {
                const res = await this.$api.put('/crons/superbills_statements');
                this.check_superbills_statements = res.data;
            },
            async check_progress_notes_cron() {
                const res = await this.$api.put(`/crons/check_progress_notes`);
                this.check_progress_notes = res.data;
            },
            async check_send_emails_cron() {
                const res = await this.$api.put(`/crons/check_send_emails`);
                this.check_send_emails = res.data;
            },
          async check_statements_superbills(){
            const res = await this.$api.put(`/crons/superbills_statements`)
            this.echo_statements_superbills = res.data
          },
            async init() {
                await this.load_data();
            }
        },
        async created() {
            if (process.env.VUE_APP_AWS_ENV == 'dev' || process.env.VUE_APP_AWS_ENV == 'stag') {
                this.aws_env = 1
            }
            this.client_id = this.$store.state.current_client_id;
            await this.init();
        },
        computed: {
            ...mapGetters(['availablePages'])
        }
    };
</script>
